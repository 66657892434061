// See how the options work here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
let options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.2
}

export const lazyLoader = (image: HTMLImageElement, src: string) => {
    const loaded = () => {
        //image.classList.add('visible')                          // doesn't work in REPL
		//image.style.opacity = "1"                                 // REPL hack to apply loading animation§
    }
    const observer = new IntersectionObserver(entries => {
        if ((!image.src || image.src==='') && (entries[0].isIntersecting || entries[0].intersectionRatio > 0)) {
			//console.log('image has loaded: ',src);                  // console log for REPL
            image.src = src;                                     // replace placeholder src with the image src on observe
            if (image.complete) {                               // check if instantly loaded
                loaded();
            } else {
                image.addEventListener('load', loaded);          // if the image isn't loaded yet, add an event listener
            }
        }
    }, options);
    setTimeout(() => observer.disconnect(), 60000);             // disconnect after 10 seconds
    image.classList.add('lazyload');                                // add lazyload class to image
    observer.observe(image);                                    // intersection observer

    return {
        destroy() {
            image.removeEventListener('load', loaded);           // clean up the event listener
        }
    }
}